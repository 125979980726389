//import globalMixin from '../mixins/global';
//import pagerMixin from '../mixins/pageable';
//import composeModules from '../shared/composeModules';

const defaultState = () => ({
    list: [],
    total: null,
    pager: {},
    request_type: 'JT',
});

const tickets = {
    namespaced: true,
    name: 'tickets',
    state: defaultState(),
    actions: {
        async getList({ commit, state }, { ...params }) {
            /*commit('setPagerData', {
                rowsPerPage: params.rowsPerPage,
                page: params.page,
            });*/

            const { data = {}, error = {} } = await this.api.restApiV1('ticketsList', { ...state.pager, ...params });
            if (Object.keys(error).length) {
                return Promise.reject(error);
            }

            const { data: list = [], total = null } = data;

            let mappedData = list.map(el => ({
                request_id: el.request_id,
                jt_number: el.jt_number,
                nonsig_number: el.nonsig_number,
                nonsig_name: el.nonsig_name,
                jt_type_code: el.jt_type_code,
                current_status: el.current_status,
                next_approver: el.next_approver,
                payment_type: el.payment_type,
                payment: el.payment,
                date_submitted: el.request_date,
                requestor_name: el.requestor_name,
                invoice_document: el.invoice_document
            }));

            return Promise.resolve({ list: mappedData, total });
        },
        async getInfo({ }, { ...params }) {
            const { data = {}, error = {} } = await this.api.restApiV1('ticketsInfo', params);

            if (Object.keys(error).length) {
                return Promise.reject(error);
            }

            const { data: info = {} } = data;
            return Promise.resolve(info);
        },
        async create({ }, { ...params }) {
            const { data = {}, error = {} } = await this.api.restApiV1('ticketsCreate', params);

            if (Object.keys(error).length) {
                return Promise.reject(error);
            }

            return Promise.resolve(_.get(data, 'data[0].insertId'));
        },
        async update({ }, { ...params }) {
            const { data = {}, error = {} } = await this.api.restApiV1('ticketsUpdate', params);

            if (Object.keys(error).length) {
                return Promise.reject(error);
            }

            return Promise.resolve({ status: data.status });
        },
        async saveChanges({ }, { ...params }) {
            const { data = {}, error = {} } = await this.api.restApiV1('ticketsChanges', params);

            if (Object.keys(error).length) {
                return Promise.reject(error);
            }

            return Promise.resolve({ status: data.status });
        },
        async changeInProcess({ }, { ...params }) {
            const { data = {}, error = {} } = await this.api.restApiV1('ticketsInProcess', params);

            if (Object.keys(error).length) {
                return Promise.reject(error);
            }

            return Promise.resolve(data);
        },
    },
};

//export default composeModules(composeModules(requests, pagerMixin), globalMixin(defaultState()));
export default tickets;
