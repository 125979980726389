import { Storage } from 'aws-amplify';
import amazonConfig from '../../config/aws-config';

export default {
    namespaced: true,
    name: 'storage',
    state: { 
        uploadProgress: null,
    },
    getters: {
        uploadProgress: state => state.uploadProgress,
    },    
    mutations: {
        setUploadProgress(state, uploadProgress) {
            state.uploadProgress = uploadProgress;
         },
    },     
    actions: {
        async putFile({ state, commit }, { ...upload }) {
            const { file, filename, filetype, metadata } = upload;
            let uploadProgress = null;
            try {
                var requestResult = await Storage.put(filename, file, {
                    customPrefix: { public: 'uploads/' },
                    contentType: filetype,
                    metadata: metadata,
                    progressCallback(progress) {
                        uploadProgress = `Uploaded: ${progress.loaded}/${progress.total}`;
                        commit('setUploadProgress', uploadProgress);
                        console.log(uploadProgress);
                  },
                });
            } catch (error) {
                return Promise.reject( error );
            }
            
            return Promise.resolve(requestResult);
        },
        async putTemplateFile({ state, commit }, { ...upload }) {
            const { file, filename, filetype, prefix, metadata } = upload;
            let uploadProgress = null;
            console.log(filename);
            try {
                var requestResult = await Storage.put(filename, file, {
                    bucket: amazonConfig.StorageTemplates.AWSS3.bucket,
                    customPrefix: { public: prefix },
                    contentType: filetype,
                    metadata: metadata,
                    progressCallback(progress) {
                        uploadProgress = `Uploaded: ${progress.loaded}/${progress.total}`;
                        commit('setUploadProgress', uploadProgress);
                        console.log(uploadProgress);
                  },
                });
            } catch (error) {
                console.log('putTemplateFile Error:', error);
                return Promise.reject( error );
            }
            
            return Promise.resolve(requestResult);
        },
        async getList({}, { ...params }) {
            const { path } = params;
            let files = [];
            try {
                files = await Storage.list(path + '/', {
                    customPrefix: { public: 'uploads/' },
                });
            } catch (error) {
                return Promise.reject( error );
            }
            
            return Promise.resolve(files.results);
        },
        async getFile({}, { ...download }) {
            const { path } = download;
            let file = null;
            try {
                file = await Storage.get(path, {
                    customPrefix: { public: 'uploads/' },
                });
            } catch (error) {
                return Promise.reject( error );
            }
            
            return Promise.resolve(file);
        },
    },
};
