<template>
<footer id="global-footer">
    <b-container>
		<div class="footer-bottom-section">
			<b-row>
                <b-col cols="12" class="copyright-container text-center">
                    <div>©{{ new Date().getFullYear() }} The Goodyear Tire & Rubber Company</div>
                    <div><a href="http://www.goodyear.com/legal/privacy.html" target="_blank">Terms & Conditions and Privacy Policy</a></div>
                </b-col>
            </b-row>
        </div>
	</b-container>
</footer>

</template>

<script>
    export default {
        name: 'Footer',
        props: {
        }
    };
</script>

<style scoped>
    #global-footer {
        background-color: #274ba7;
        font-family: "Barlow", sans-serif;
        font-style: normal;
        font-weight: 400;
        padding: 20px 0;
    }

    .copyright-container {
        color: #fff;
    }

    .copyright-container a {
        color: #fd0;
    }
</style>