import requests from '../modules/requests';
//import users from '../modules/users';
import reports from '../modules/reports';
import sap from '../modules/sap';
import tickets from '../modules/tickets';

const modules = {
    requests,
    //users,
    reports,
    sap,
    tickets,
};

export default (name, apiUrl) => modules[name](apiUrl);
