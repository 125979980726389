<template>
    <div class="auth-spinner text-center">
        <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
    </div>
</template>

<script>

export default {
    name: 'AuthPage',
    /*computed: {
        isAuthenticated() {
            return this.$store.getters['auth/isAuthenticated'];
        },
    },*/
    mounted() {
        this.$store.commit('auth/setAmplifyConfig', 'GOpass');
        this.$store.commit('auth/setAuthGoPass');
    },
};
</script>

<style>
    .auth-spinner {
        min-height: 500px;
        padding-top: 100px;
    }

    spinner {
        color: #274ba7;
    }
</style>