import { Amplify, Auth } from 'aws-amplify';
import amazonConfig from '../../config/aws-config';
import { generateVerification } from '../../utils/mainUtils';
import { LOWEST_ROLE_PRIORITY, VERIFICATION_GENERATION_RANDOM_NUMBER } from '../../config/constants';
import { roles } from '../../config/users';

export default {
    namespaced: true,
    state: {
        userRebateRoleType: null,
        userJTRoleType: null,
        verification: null,
        user: {},
        credentials: {},
        amplifyConfig: localStorage.getItem('amplify-config') || 'GOpass',
    },

    getters: {
        verification: state => state.verification,
        amplifyConfig: state => state.amplifyConfig,
        isAuthenticated: state => state.user && state.user.signInUserSession,
        userSession(state, getters) {
            if (getters.isAuthenticated) {
                return {
                    username: state.user.username,
                    userid: state.user.signInUserSession.idToken.payload['custom:user_id'],
                    sub: state.user.signInUserSession.idToken.payload.sub,
                    displayname:
                        state.user.signInUserSession.idToken.payload.given_name + ' ' +
                        state.user.signInUserSession.idToken.payload.family_name,
                    firstname: state.user.signInUserSession.idToken.payload.given_name,
                    email: state.user.signInUserSession.idToken.payload.email,
                    groups: state.user.signInUserSession.idToken.payload['custom:roles'],
                    rebateRole: state.userRebateRoleType,
                    jtRole: state.userJTRoleType,
                };
            }

            return {
                username: '',
                userid: '',
                sub: '',
                displayname: '',
                firstname: '',
                email: '',
                groups: [],
                rebateRole: '',
                jtRole: '',
            };
        },
        adminAccess(state, getters) {
            return getters.userSession.rebateRole === 'admin';
        },
        nonViewerAccess(state, getters) {
            return ['admin', 'manager'].includes(getters.userSession.rebateRole);
        },
    },

    mutations: {
        setAmplifyConfig(state, amplifyConfig) {
            state.amplifyConfig = amplifyConfig;
            localStorage.setItem('amplify-config', amplifyConfig);
        },
        setUserCredentials(state, { user = {}, credentials = {} }) {
            state.user = user;
            state.credentials = credentials;
        },
        clearLocalStorage() {
            if (localStorage) {
                localStorage.clear();
            }
        },
        setAuthGoPass(state) {
            const goPassAuthConfig = amazonConfig.GOpass.Auth;
            const goPassAuthConfigOAuth = amazonConfig.GOpass.Auth.oauth;
            const { domain, redirectSignIn, responseType } = goPassAuthConfigOAuth;
            const { userPoolWebClientId, identityProvider } = goPassAuthConfig;
            const verification = generateVerification(VERIFICATION_GENERATION_RANDOM_NUMBER);

            state.verification = verification;
 
            // Go straight to the provider, skipping the hosted UI
            const url = `https://${domain}/oauth2/authorize?identity_provider=${identityProvider}&response_type=${responseType}&client_id=${userPoolWebClientId}&redirect_uri=${redirectSignIn}&state=${verification}`;
            window.location.href = url;
        },
        setUserRole(state, reset = false) {
            if (reset) {
                state.userRebateRoleType = null;
                state.userJTRoleType = null;

                return;
            }
            
            const groupsArray = _.get(state.user.signInUserSession.idToken.payload,'custom:roles');
            const authType = _.get(state.user.storage, 'amplify-config'); //'GOpass';

            let currentRole, currentRebateRole, currentRebateRolesArray, priorityRebateValue, currentJTRole, currentJTRolesArray, priorityJTValue;

            if (groupsArray && authType === 'GOpass') {
                switch (groupsArray.length) {
                    case 0:
                        state.userRebateRoleType = 'creator';
                        state.userJTRoleType = 'creator';
                        break;
                    case 1:
                        currentRole = roles.find(item => item.azureRole === groupsArray[0]);

                        switch(currentRole.type){
                            case 'rebate':
                                state.userRebateRoleType = currentRole.innerRole;
                                state.userJTRoleType = 'creator';
                                break;
                            case 'jt':
                                state.userRebateRoleType = 'creator';
                                state.userJTRoleType = currentRole.innerRole;
                                break;
                            case 'admin':
                                state.userRebateRoleType = currentRole.innerRole;
                                state.userJTRoleType = currentRole.innerRole;
                                break;
                        }
                        break;
                    default:
                        currentRebateRolesArray = roles.filter(item => groupsArray.includes(item.azureRole) && (item.type === 'rebate' || item.type === 'admin'));
                        currentJTRolesArray = roles.filter(item => groupsArray.includes(item.azureRole) && (item.type === 'jt' || item.type === 'admin'));

                        priorityRebateValue = currentRebateRolesArray.reduce((mainPriority, currentPriority) => {
                            return mainPriority < currentPriority.priority ? mainPriority : currentPriority.priority;
                        }, LOWEST_ROLE_PRIORITY);

                        priorityJTValue = currentJTRolesArray.reduce((mainPriority, currentPriority) => {
                            return mainPriority < currentPriority.priority ? mainPriority : currentPriority.priority;
                        }, LOWEST_ROLE_PRIORITY);
                        
                        currentRebateRole = roles.find(item => item.priority === priorityRebateValue && (item.type === 'rebate' || item.type === 'admin')) || 'creator';
                        currentJTRole = roles.find(item => item.priority === priorityJTValue && (item.type === 'jt' || item.type === 'admin')) || 'creator';

                        state.userRebateRoleType = currentRebateRole.innerRole;
                        state.userJTRoleType = currentJTRole.innerRole;
                }
            } else if (groupsArray && authType !== 'GOpass') {
                // TODO: Add action for groups of GoPass and other third-party services
                state.userRebateRoleType = 'creator';
                state.userJTRoleType = 'creator';
            } else {
                state.userRebateRoleType = 'creator';
                state.userJTRoleType = 'creator';
            }
        },
    },

    actions: {
        async getUser({ commit }) {
            let requestResult;

            try {
                requestResult = await Auth.currentAuthenticatedUser({bypassCache: true});
            } catch (e) {
                commit('setUserCredentials', { user: {}, credentials: {} });
                commit('setUserRole', true);

                return Promise.resolve({ user: {} });
            }
            
            commit('setUserCredentials', { user: requestResult, credentials: {} });
            commit('setUserRole');

            return Promise.resolve({ user: requestResult });
        },
        async signOut({ commit, getters }) {
            if (!getters.isAuthenticated) {
                console.error('The user is not logged in.');
            }

            try {
                await Auth.signOut();
            } catch (error) {
                return Promise.reject({ error });
            }

            commit('clearLocalStorage');
            commit('setUserCredentials', { user: {}, credentials: {} });
            commit('setUserRole', true);

            return Promise.resolve();
        },
        async initAmplify({ getters }) {
            const amplifyConfig = amazonConfig[getters.amplifyConfig].Auth;
            const storage = amazonConfig.Storage; // for S3 uploads

            Amplify.configure({ Auth: amplifyConfig, Storage: storage });
            //Amplify.configure({ Auth: amplifyConfig });
        },
    },
};
