export function toLowerCase(string) {
    if (typeof string !== 'string') {
        return '';
    }

    return string.charAt(0).toLowerCase() + string.slice(1);
}

export function numberWithCommas(number) {
    if (typeof number !== 'number' || number === 0) {
        return number;
    }

    if (number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return null;
}

export function generateVerification(stringLength) {
    let verification = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < stringLength; i += 1) {
        verification += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return verification;
}

export const idGenerator = (i => () => (i += 1))(0);

export const capitalize = text => text && text.charAt(0).toUpperCase() + text.slice(1);