import restApiRequest from '../apiRequestClients/restApiRequest';
import applyPagerParams from '../utils/pagerParser';
import applyFilterParams from '../utils/filterParser';
import applySortingParams from '../utils/sortingParser';

const BASE_URL = '/reports';

export default apiUrl => {
    const apiRequest = restApiRequest(apiUrl);

    return {
        list(params = {}) {
            const sendParams = { url: BASE_URL, data: {} };

            Object.assign(sendParams.data, applyPagerParams(params));
            //   role - user id from cognito authorizer claim, validate role in claim 
            //Object.assign(sendParams.data, applyFilterParams(params, { owner: 'owner_id', type: 'type' }));
            Object.assign(sendParams.data, applyFilterParams(params, 
                { request_type: 'request_type', 
                agreement_no: 'agreement_no',
                valid_from_date: 'valid_from_date',
                valid_to_date: 'valid_to_date',
                common_owner_number: 'common_owner_number',
                common_owner_name: 'common_owner_name',
                customer_group: 'customer_group',
                pbu_name: 'pbu_name',
                request_status: 'request_status',
                requestor: 'requestor',
                request_from_date: 'request_from_date',
                request_to_date: 'request_to_date',
                completion_to_date: 'completion_to_date',    
                completion_from_date: 'completion_from_date',
                jt_number: 'jt_number',
                title: 'title',
                nonsig_number: 'nonsig_number',
                nonsig_name: 'nonsig_name',
                payment_period: 'payment_period',
                invoice_document: 'invoice_document',    
            }));

            Object.assign(sendParams.data, applySortingParams(params)); //, { type: 'type_name' }));
            return apiRequest.get(sendParams);
        },
        info({ id } = {}) {
            if (!id || typeof id !== 'number') {
                throw new Error({ error: `Expected requestId param to be Number, got ${typeof id}` });
            }

            const sendParams = { url: `${BASE_URL}/${id}` };

            return apiRequest.get(sendParams);
        },
        statusList(params = {}) {
            const sendParams = { url: `${BASE_URL}/status`, data: {} };

            //Object.assign(sendParams.data, applyPagerParams(params));
            //   role - user id from cognito authorizer claim, validate role in claim 
            //Object.assign(sendParams.data, applyFilterParams(params, { owner: 'owner_id', type: 'type' }));
            Object.assign(sendParams.data, applyFilterParams(params, { request_type: 'request_type' }));
            //Object.assign(sendParams.data, applySortingParams(params, { type: 'type_name' }));

            return apiRequest.get(sendParams);
        },
        userList(params = {}) {
            const sendParams = { url: `${BASE_URL}/users`, data: {} };

            //Object.assign(sendParams.data, applyPagerParams(params));
            //   role - user id from cognito authorizer claim, validate role in claim 
            //Object.assign(sendParams.data, applyFilterParams(params, { owner: 'owner_id', type: 'type' }));
            Object.assign(sendParams.data, applyFilterParams(params, { request_type: 'request_type' }));
            //Object.assign(sendParams.data, applySortingParams(params, { type: 'type_name' }));

            return apiRequest.get(sendParams);
        },
        historyInfo({ id } = {}) {
            if (!id || typeof id !== 'number') {
                throw new Error({ error: `Expected requestId param to be Number, got ${typeof id}` });
            }

            const sendParams = { url: `${BASE_URL}/${id}/history` };

            return apiRequest.get(sendParams);
        },
    };
};
