<template>
    <div class="spinner-container text-center">
        <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
    </div>
</template>

<script>
    import { REDIRECT_HOME_TIMEOUT } from '../config/constants';

    export default {
        name: 'MainLoader',
        mounted() {
            // We use it because Cognito events (router/index.js) sometimes goes down and this bug is not on our side.
            // This is our protection and it makes authentication flow be more "user friendly".
            setTimeout(() => {
                if (this.$route.name === 'MainLoader') {
                    this.$router.push({ name: 'Home' });
                }
            }, REDIRECT_HOME_TIMEOUT);
        },
    };
</script>

<style>
    .spinner-container {
        min-height: 500px;
    }
</style>

