const xlsx = require('xlsx');

const requestsPerTemplateLimits = {
    RBT: 300,
    JT: 100
}

export async function isTemplateFileValid(file, templateType) {
    let result = {
        isValid: true,
        errorMessage: '',
    }

    const validationFunc = templateType === 'RBT' ? validateRebateRequest : validateJTRequest;

    const requestsPerTemplateLimit = requestsPerTemplateLimits[templateType];

    const fileData = await file.arrayBuffer();
    const workbook = xlsx.read(fileData);
    const jws = xlsx.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { range: 6, raw: true });

    try {
        const requests = jws.filter((row) => { return !isRowEmpty(row) });

        if (requests.length > requestsPerTemplateLimit) {
            throw new Error(`A maximum of ${requestsPerTemplateLimit} records per template is allowed. The attached file contains ${requests.length} records.`)
        }

        for (const request of requests) {
            validationFunc(request);
        }
    } catch (error) {
        result.isValid = false;
        result.errorMessage = error.message;
    }

    return result;
}

const isRowEmpty = (row) => Object.entries(row).every(([key, value]) => {
    if (typeof value === 'undefined' || value === null || (typeof value === 'string' && value.trim() === '')) {
        return true;
    }

    return key === '__rowNum__';
});

function validateJTRequest(request) {
    if (typeof request['*JT or SDA'] === 'undefined' || request['*JT or SDA'] === '') {
        throw new Error(`Row ${request.__rowNum__ + 1}: JT or SDA is required`);
    }

    if ((typeof request['*Approval # (N/A if Journal Ticket)'] === 'undefined' || request['*Approval # (N/A if Journal Ticket)'] === '') && request['*JT or SDA'] === 'SDA') {
        throw new Error(`Row ${request.__rowNum__ + 1}: Approval # is required`);
    }

    if (typeof request['*Title/Subject'] === 'undefined' || request['*Title/Subject'] === '') {
        throw new Error(`Row ${request.__rowNum__ + 1}: Subject is required`);
    }

    if (typeof request['*Customer #'] === 'undefined' || request['*Customer #'] === '') {
        throw new Error(`Row ${request.__rowNum__ + 1}: Customer # is required`);
    }

    if (typeof request['*Debit/Credit'] === 'undefined' || request['*Debit/Credit'] === '') {
        throw new Error(`Row ${request.__rowNum__ + 1}: Debit/Credit is required`);
    }

    if (typeof request['*Amount'] === 'undefined' || request['*Amount'] === '') {
        throw new Error(`Row ${request.__rowNum__ + 1}: Amount is required`);
    }

    if (typeof request['*Accounting Info (e.g. GL Account, Cost/Profit Center, etc.)'] === 'undefined' || request['*Accounting Info (e.g. GL Account, Cost/Profit Center, etc.)'] === '') {
        throw new Error(`Row ${request.__rowNum__ + 1}: Accounting Info is required`);
    }

    if (typeof request['*Additional Information / Business Justification'] === 'undefined' || request['*Additional Information / Business Justification'] === '') {
        throw new Error(`Row ${request.__rowNum__ + 1}: Additional Information / Business Justification is required`);
    }

    if (typeof request['*Attachment (Y/N)'] === 'undefined' || request['*Attachment (Y/N)'] === '') {
        throw new Error(`Row ${request.__rowNum__ + 1}: Attachment (Y/N) is required`);
    }

    if ((typeof request['Instance # (For Attachments Only)'] === 'undefined' || request['Instance # (For Attachments Only)'] === '') && request['Attachment (Y/N)'] == 'Y') {
        throw new Error(`Row ${request.__rowNum__ + 1}: Attachment Instance # is required`);
    }
}

function validateRebateRequest(request) {
    if (typeof request['Subject'] === 'undefined' || request['Subject'] === '') {
        throw new Error(`Row ${request.__rowNum__ + 1}: Subject is required`);
    }

    if (typeof request['Rebate Agreement Number'] === 'undefined' || request['Rebate Agreement Number'] === '') {
        throw new Error(`Row ${request.__rowNum__ + 1}: Rebate Agreement Number is required`);
    }

    if (typeof request['Final/Partial Settlement'] === 'undefined' || request['Final/Partial Settlement'] === '') {
        throw new Error(`Row ${request.__rowNum__ + 1}: Final/Partial Settlement is required`);
    }

    if (typeof request['Settlement Amount'] === 'undefined' || request['Settlement Amount'] === '') {
        throw new Error(`Row ${request.__rowNum__ + 1}: Settlement Amount is required`);
    }

    if (typeof request['Credit Memo Text'] === 'undefined' || request['Credit Memo Text'] === '') {
        throw new Error(`Row ${request.__rowNum__ + 1}: Credit Memo Text is required`);
    }

    if (typeof request['Attachment (Y/N)'] === 'undefined' || request['Attachment (Y/N)'] === '') {
        throw new Error(`Row ${request.__rowNum__ + 1}: Attachment (Y/N) is required`);
    }

    if ((typeof request['Instance # (For Attachments Only)'] === 'undefined' || request['Instance # (For Attachments Only)'] === '') && request['Attachment (Y/N)'] == 'Y') {
        throw new Error(`Row ${request.__rowNum__ + 1}: Attachment Instance # is required`);
    }
}
