export default ({ sortBy = null, sort = {}, descending = null }, schema = {}) => {
    const field = Object.keys(sort)[0] || sortBy;
    const sortField = schema[field] || field;
    const desc = descending || Object.values(sort)[0] === 'DESC';

    const result = {};

    if (sortField && typeof desc === 'boolean') {
        result.sort = `${sortField}:${desc ? 'desc' : 'asc'}`;
    }

    return result;
};
