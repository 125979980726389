<template>
    <div>
        <div class='tableauPlaceholder d-md-none d-lg-none'>
            <object class='tableauViz' width='100%' height='100%' style='display:none;'>
                <param name='host_url' value='https://tableaudev.goodyear.com/t/GoodyearAmericas/views/TESTTradeSpendDashboard/Dashboard1?iframeSizedToWindow=true&:embed=y&:showAppBanner=false&:display_count=no&:showVizHome=no&:origin=viz_share_link' /> 
                <param name='device' value='phone' />
            </object>
        </div>
        <div class='tableauPlaceholder d-none d-sm-none d-md-block d-lg-block'>
            <object class='tableauViz' width='100%' height='100%' style='display:none;'>
                <param name='host_url' value='https://tableaudev.goodyear.com/t/GoodyearAmericas/views/TESTTradeSpendDashboard/Dashboard1?iframeSizedToWindow=true&:embed=y&:showAppBanner=false&:display_count=no&:showVizHome=no&:origin=viz_share_link' /> 
            </object>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'Tableau',
        mounted() {
            const tableau = document.createElement("script");
            tableau.setAttribute(
              "src",
              "https://tableaudev.goodyear.com/javascripts/api/viz_v1.js"
            );
            document.head.appendChild(tableau);
        }
    };
</script>

<style scoped>

.tableauPlaceholder, .tableauViz {
    min-height: 1200px;
}
</style>