
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import saveAs from 'file-saver';

export async function downloadAndZipFiles(fileUrls, zipName) {
    let zip = new JSZip();

    for (const fileUrl of fileUrls) {
        let fileName = decodeURI(new URL(fileUrl).pathname.split('/').pop());
        // loading a file and add it in a zip file
        let data = await JSZipUtils.getBinaryContent(fileUrl);

        zip.file(fileName, data, { binary: true });
    }

    zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, `${zipName}.zip`);
    });
}

