<template>
    <div>
        <b-navbar toggleable="lg" class="custom-gy">
            <b-navbar-brand to="/"><img src="../assets/images/goodyear-logo.png" alt="Goodyear" /></b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <!-- Right aligned nav items -->
                <b-navbar-nav class="ml-auto">
                    <b-nav-item @click="onNewJournalTicket">New Journal Ticket</b-nav-item>
                    <b-nav-item to="my-journal-tickets">My Queue</b-nav-item>
                    <b-nav-item to="jt-template-upload">Upload Templates</b-nav-item>
                    <b-nav-item to="search-journal-tickets">Search</b-nav-item>
                    <b-nav-item-dropdown class="nav-guides" text="Guides and Templates" right>
                        <b-dropdown-item class="nav-guides-item" href="/docs/guides/Journal Ticket Submission How-To.pdf" target="_blank">Submission How-To</b-dropdown-item>
                        <b-dropdown-item class="nav-guides-item" href="/docs/guides/Journal Ticket Template Upload How-To.pdf" target="_blank">Template Upload How-To</b-dropdown-item>
                        <b-dropdown-item class="nav-guides-item" href="/docs/templates/Journal Ticket Upload Template.xlsx" target="_blank">Upload Template</b-dropdown-item>
                    </b-nav-item-dropdown>
                    <b-nav-item-dropdown class="nav-username" v-bind:text="navName" v-if="isAuthenticated" right>
                      <b-dropdown-item disabled class="nav-profile-item displayname text-center">{{userSession.displayname}}</b-dropdown-item>
                      <b-dropdown-item disabled class="nav-profile-item email text-center">{{userSession.email}}</b-dropdown-item>
                      <b-dropdown-item disabled class="nav-profile-item role text-center">Role: {{userSession.jtRole}}</b-dropdown-item>
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item href="#" @click="onCognitoLogout" class="nav-profile-item logout">Sign Out</b-dropdown-item>
                    </b-nav-item-dropdown>
                    <div class="nav-username" v-if="isAuthenticated"></div>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
    export default {
        name: 'RebateNavbar',
        props: {
        },
        computed: {
            isAuthenticated() {
                return this.$store.getters['auth/isAuthenticated'];
            },
            userSession() {
                return this.$store.getters['auth/userSession'];
            },
            adminAccess() {
                return this.$store.getters['auth/adminAccess'];
            },
            navName() {
                return this.userSession.firstname + ' (' + this.userSession.jtRole + ')';
            }
        },
        methods: {
            onCognitoLogout() {
                this.$store
                    .dispatch('auth/signOut')
                    .then(() => this.$router.push({ name: 'SignedOut' }))
                    .catch(error => {
                        this.$store.dispatch('notifications/addNotification', {
                            message: 'Oops, seems like we have a problem with signout action. Please, try again.',
                            type: 'error',
                        });

                        console.error(`Can't processed signout action, error: ${JSON.stringify(error)}`);
                    });
            },
            onNewJournalTicket() {
                // If user clicks to 'New Journal Ticket' link while being on /new-journal-ticket page already
                // and there is no id param in query.
                if (this.$route.path === '/new-journal-ticket' && !this.$route.query.id) {
                    // This will reset the component data
                    this.$eventBus.$emit('reset-new-journal-ticket-data');
                    return;
                } 
                // else go to /new-journal-ticket (to navigate or remove id param from query)
                this.$router.push({ path: `/new-journal-ticket` })
            },
        }
    };
</script>

<style scoped>
    .navbar.custom-gy { 
        background-color: #274ba7; 
    }

    .navbar a {
        color: #fd0;
    }

    .navbar-light .navbar-nav .nav-link:active, .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .show > .nav-link {
        color: #fff;
        text-decoration: underline;
    }

    .navbar.custom-gy a.nav-link {
        color: #fd0;
        font-family: "Barlow", sans-serif; 
        font-style: normal;
        font-weight: 400;
        padding: 8px 8px 8px 20px;
        text-transform: uppercase;
    }

    .navbar.custom-gy a:hover, .navbar.custom-gy a:focus, .navbar.custom-gy a:active {
        color: #fd0;
        text-decoration: underline;
    }

    .dropdown-menu .dropdown-item {
        color: #333;
    }

    .nav-username {
        color: #fff !important;
        font-family: "Barlow", sans-serif; 
        font-style: normal;
        font-weight: 400;
        padding-left: 10px;
    }

    .navbar.custom-gy .nav-username .nav-link {
        color: #fff;
    }

    .navbar.custom-gy .nav-username .nav-link:last-child {
        color: #fd0;
    }
    
    .nav-profile-item.displayname {
        font-size: 18px;
    }

    .nav-profile-item.email {
        padding-bottom: 5px;
    }

    .nav-profile-item.logout {
        border: 1px solid lightgray;
        max-width: 80%;
        margin: 0 auto;
        text-align: center;
    }

    .nav-guides{
        font-family: "Barlow", sans-serif; 
        font-style: normal;
        font-weight: 400;
        text-transform: uppercase;
        padding-left: 20px;
    }

    .nav-guides-item{
        text-transform: none;
    }
</style>