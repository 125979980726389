<template>
    <div class="notfound text-center">
        <b-container>
            <h1>Sorry about that - we couldn't find the page you're looking for.</h1>
            <p>You may have clicked a link to a page that no longer exists, or it's possible there was a typo in the URL.</p>
            <p>You can try searching the site from our menu above or visit our home page.</p>
            <router-link v-bind:to="`/`">Go to Home Page</router-link>
        </b-container>
    </div>
</template>


<script>
export default { name: 'Page404' };
</script>

<style>
    .notfound .container {
        min-height: 500px;
    }
</style>
