<template>
    <div class="Search">
        <b-container>
            <div class="form-filters">
                <h1>Search Journal Tickets</h1>
                <b-card no-body class="mb-1" role="tablist">
                    <b-card-header header-tag="header" class="p-0" role="tab">
                        <b-button block href="#" v-b-toggle.accordion-1>Search Filters</b-button>
                    </b-card-header>
                    <b-collapse id="accordion-1" visible appear accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <b-form v-if="show">
                                <b-row>
                                    <b-col>
                                        <b-form-group id="jt-number-group" label="JT/SDA Number:" label-for="jt-number">
                                            <b-form-input
                                                id="jt-number"
                                                v-model="form.jtNumber"
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group id="title-group" label="Title:" label-for="title">
                                            <b-form-input
                                                id="title"
                                                v-model="form.title"
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <b-form-group id="nonsig-number-group" label="Customer Number:" label-for="nonsig-number">
                                            <b-form-input
                                                id="nonsig-number"
                                                v-model="form.nonsigNumber"
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group id="nonsig-name-group" label="Customer Name:" label-for="nonsig-name">
                                            <b-form-input
                                                id="nonsig-name"
                                                v-model="form.nonsigName"
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <b-form-group label="Status:" label-for="request-status">
                                            <b-form-select id="request-status" v-model="form.requestStatus">
                                                <option value="">No Selection</option>
                                                <option v-for="st in status" v-bind:key="st.status_code" v-bind:value="st.status_code">{{st.status}}</option>
                                            </b-form-select>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group label="Requestor:" label-for="requestor">
                                            <b-form-select id="requestor" v-model="form.requestor">
                                                <option value="">No Selection</option>
                                                <option v-for="user in users" v-bind:key="user.user_id" v-bind:value="user.user_id">{{user.user_name}}</option>
                                            </b-form-select>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <b-form-group>
                                            <label for="requestor-submission-from">Requestor Submission From Date:</label>
                                            <b-form-datepicker 
                                                id="requestor-submission-from" 
                                                v-model="form.requestFromDate"
                                                today-button
                                                reset-button
                                                close-button      
                                                locale="en">
                                            </b-form-datepicker>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group>
                                            <label for="requestor-submission-to">Requestor Submission To Date:</label>
                                            <b-form-datepicker 
                                                id="requestor-submission-to" 
                                                v-model="form.requestToDate"
                                                today-button
                                                reset-button
                                                close-button      
                                                locale="en">
                                            </b-form-datepicker>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <b-form-group>
                                            <label for="completion-from">Final Completion From Date:</label>
                                            <b-form-datepicker 
                                                id="completion-from" 
                                                v-model="form.completionFromDate"
                                                today-button
                                                reset-button
                                                close-button      
                                                locale="en">
                                            </b-form-datepicker>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group>
                                            <label for="completion-to">Final Completion To Date:</label>
                                            <b-form-datepicker 
                                                id="completion-to" 
                                                v-model="form.completionToDate"
                                                today-button
                                                reset-button
                                                close-button      
                                                locale="en">
                                            </b-form-datepicker>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col class="text-center">
                                        <b-button type="button" class="btn-yellow" v-on:click="search()">Search</b-button>
                                    </b-col>          
                                </b-row>
                            </b-form>                        
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>
            <Modal
                :force-open="modalNotifications"
                header="New Rebate Payment"
                :content="content"
                :variant="modalVariant"
                @close="onCloseNotifications"
            />
        </b-container>
        <br>
        <b-container fluid>
            <b-row>
                    <b-col cols="6" md="2" offset-md="5" class="text-center">
                        <b-button type="button" class="btn-yellow mt-1 mb-3" v-on:click="getItemsExport()">Export</b-button>
                    </b-col>
                    <b-col cols="6" md="3" offset-md="2" class="text-right">
                        <div class="mt-3 mb-2">
                            <span style="margin: 4px;">Show</span>
                            <b-form-select
                                    v-model="rowsPerPage"
                                    :options="perPageOptions"
                                    size="sm"
                                    class="select-rows"
                            ></b-form-select>
                            <span style="margin: 4px;">results</span>
                        </div>  
                </b-col>
            </b-row>
            <b-table 
                id="search-table"
                ref="searchTable"
                table-class="search-table"
                striped 
                bordered 
                hover 
                :items="getItems" 
                :fields="fields"
                :per-page="rowsPerPage"
                :current-page="currentPage"
                :filter="filter"
                responsive="sm" 
                @row-clicked="expandAdditionalInfo"
            >
                <template #cell(jt_number)="row">
                    <router-link class="request-link" :to="{ name: 'New Journal Ticket', query: { id: row.item.request_id }}">{{row.item.jt_number}}</router-link>
                </template>
                <template slot="row-details" slot-scope="row">
                    <p v-for="item in history[row.item.request_id]" :key="item.history_status">{{item.history_status}}</p>
                    <router-link class="request-link" :to="{ name: 'New Journal Ticket', query: { id: row.item.request_id }}">View All Details <font-awesome-icon icon="chevron-circle-right" /></router-link>
                </template>
            </b-table>
            <b-pagination
                pills
                class="custom-pagination"
                v-model="currentPage"
                :per-page="rowsPerPage"
                :total-rows="rows"
                first-text="First"
                last-text="Last"
                align="center"
                aria-controls="search-table"
            ></b-pagination>
        </b-container>
    </div>
</template>

<script>

import * as XLSX from 'xlsx/xlsx.mjs';
import Modal from './Modal'

    export default {
        components: { Modal }, 
        data() {
            return {
                show: [],
                form: {
                    jtNumber: '',
                    title: '',
                    nonsigNumber: '',
                    nonsigName: '',
                    requestStatus: '',
                    requestor: '',
                    requestFromDate: '',
                    requestToDate: '',
                    completionFromDate: '',
                    completionToDate: ''
                },
                fields: [
                    {
                        key: 'jt_number',
                        label: 'Journal Ticket #',
                        sortable: true
                    },
                    {
                        key: 'nonsig_number',
                        label: 'Nonsig #',
                        sortable: true
                    },
                    {
                        key: 'nonsig_name',
                        label: 'Name',
                        sortable: true
                    },
                    {
                        key: 'current_status',
                        label: 'Current Status',
                        sortable: true
                    },
                    {
                        key: 'next_approver',
                        label: 'Next Approver',
                        sortable: true
                    },
                    {
                        key: 'invoice_document',
                        label: 'Invoice Document',
                        sortable: true
                    },
                    {
                        key: 'payment_type',
                        label: 'Payment Type',
                        sortable: true
                    },
                    {
                        key: 'payment_amount',
                        label: 'Payment Amount',
                        sortable: true,
                    },
                    {
                        key: 'title',
                        label: 'Title',
                        sortable: true,
                    },
                    {
                        key: 'request_date',
                        label: 'Date Submitted',
                        sortable: true,
                    }
                ],
                items: [],
                exportItems: [],
                selected: [],
                info: {},
                status: [],
                users: [],
                history: {},
                rowsPerPage: 10,
                perPageOptions: [10, 25, 50, 100],
                currentPage: 1,
                rows: 0,
                filter: '',
                first: true,
                modalNotifications: false,
                content: '',
                modalVariant: 'success',
                request_type: 'JT',
            }
        },
        methods: {
            search(){
                this.$refs.searchTable.refresh();
            },
            getItems(ctx) {
                if (this.first) {
                    this.first = false;
                    return []
                }
                const params = this.getSearchParams();
                let sortParams = {};
                if (ctx.sortBy && typeof ctx.sortDesc === 'boolean') {
                    sortParams = {
                        [ctx.sortBy]: ctx.sortDesc ? 'DESC' : 'ASC' 
                    }
                }
                const promise = 
                    this.$store
                        .dispatch('reports/getList', { filters: params, rowsPerPage: ctx.perPage, page: ctx.currentPage, sort: sortParams });
                return promise.then(({ list, total }) => {
                        this.items = list;
                        this.items.forEach((item) => {
                            this.$set(item, '_showDetails', false)
                        }); 
                        this.rows = total;
                        return(this.items);
                })
                .catch(error => {
                    console.error(error);
                    this.content = error.message;
                    this.modalVariant = 'error';
                    this.modalNotifications = true;
                    return []  
                });
            },
            getItemsExport() {
                const params = this.getSearchParams();
                this.$store
                    .dispatch('reports/getExportList', { filters: params })
                    .then(({ list, total }) => {
                        const data = XLSX.utils.json_to_sheet(list)
                        const wb = XLSX.utils.book_new()
                        XLSX.utils.book_append_sheet(wb, data, 'data')
                        XLSX.writeFile(wb,'jticket.xlsx')
                    })
                    .catch(error => {
                        console.error(error);
                        this.content = error.message;
                        this.modalVariant = 'error';
                        this.modalNotifications = true;
                    });
            },
            getList() {
                const params = this.getSearchParams();
                this.$store
                    .dispatch('reports/getList', { filters: params })
                    .then(({ list, total }) => {
                        this.items = list;
                        this.items.forEach((item) => {
                            this.$set(item, '_showDetails', false)
                        }); 
                    })
                    .catch(error => {
                        console.error(error);
                        this.content = error.message;
                        this.modalVariant = 'error';
                        this.modalNotifications = true; 
                    });                           
            },
            getSearchParams() {
                return {
                    _showDetails: false,
                    request_type: this.request_type,
                    jt_number: this.form.jtNumber,
                    title: this.form.title,
                    nonsig_number: this.form.nonsigNumber,
                    nonsig_name: this.form.nonsigName,
                    request_status: this.form.requestStatus,
                    requestor: this.form.requestor,
                    request_from_date: this.form.requestFromDate,
                    request_to_date: this.form.requestToDate,
                    completion_from_date: this.form.completionFromDate,
                    completion_to_date: this.form.completionToDate            
                }
            },
            /*getReportInfo(id) {
                this.$store
                    .dispatch('reports/getInfo', { id })
                    .then(info => {
                        this.info = info;
                        alert(JSON.stringify(this.info));
                    })
                    .catch(error => console.error(`Can't get rebate data with id: ${id}, error: ${JSON.stringify(error)}`));
            },*/
            getStatusList() {
                //alert('in getUserList');
                this.$store
                    .dispatch('reports/getStatusList', { filters: { request_type: this.request_type } })
                    .then(({ list, total }) => {
                        let listSorted = list.sort();
                        this.status = listSorted;
                        //alert(JSON.stringify(this.status));
                    })
                    .catch(error => {
                        console.error(error);
                        this.content = error.message;
                        this.modalVariant = 'error';
                        this.modalNotifications = true;
                    });
                },
            getUserList() {
                //alert('in getUserList');
                this.$store
                    .dispatch('reports/getUserList', { filters: { request_type: this.request_type } })
                    .then(({ list, total }) => {
                        let listSorted = list.sort();
                        this.users = listSorted;
                        //alert(JSON.stringify(this.users));
                    })
                    .catch(error => {
                        console.error(error);
                        this.content = error.message;
                        this.modalVariant = 'error';
                        this.modalNotifications = true;
                    });                           
            },
            getHistoryInfo(id) {
                this.$store
                    .dispatch('reports/getHistoryInfo', { id })
                    .then(info => {
                        // To show all activity chain
                        this.$set(this.history, id, info);

                        // To show only last activity details
                        //this.$set(this.history, id, [info[info.length - 1]]);
                    })
                    .catch(error => {
                        console.error(error);
                        this.content = error.message;
                        this.modalVariant = 'error';
                        this.modalNotifications = true;
                    });                            
            },
            expandAdditionalInfo(row) {
                row._showDetails = !row._showDetails;
                if (row._showDetails && this.history[row.request_id] === undefined) {
                    this.getHistoryInfo(row.request_id);
                }
            },
            onCloseNotifications() {
                this.modalNotifications = false;
            },
        },
        mounted: function () {
            this.getUserList();
            this.getStatusList();
        }
  }
</script>

<style scoped>
    .Search {
        margin-bottom: 60px;
    }

    .Search .container-fluid {
        min-height: 500px;
    }

    .form-filters {
        max-width: 780px;
        margin: 0 auto;
    }

    .btn-block {
        background-color: #737b8a;
        font-family: "Barlow", sans-serif;
        font-style: normal;
        font-weight: 700;
    }

    .btn-block a {
        color: #fff;
    }

    .card-body {
        background-color: #dbdfe4;
    }

    .table-hover tbody tr:hover {
        cursor: pointer;
    }

    .table-hover tbody tr.b-table-details:hover {
        cursor: unset;
    }

    /*select # of rows dropdown*/
    .select-rows {
        max-width: 75px;
    }

</style>