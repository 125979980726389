import restApi from './apiRequestClients/restApi';
import defaultConfig from './config';
import { toLowerCase } from '../utils/mainUtils';

export default class {
    constructor({ apiConfig } = {}) {
        this.config = { ...defaultConfig, ...apiConfig };
        //this.config = { ...apiConfig };
    }

    setConfig(config = {}) {
        this.config = { ...this.config, ...config };
    }

    composeApiUrl(config = {}) {
        //const { host = '', url = '', version, type, env } = { ...this.config, ...config };
        const { url = '', version, env } = { ...this.config, ...config };
        
        if (!url) {
            throw Error({ message: 'Api url must be specified!' });
        }

        //return `${host}${url}${env ? `/${env}` : ''}/${version}${type ? `/${type}` : ''}`;
        return `${url}${env ? `/${env}` : ''}/${version}`;
    }

    restApiV1(method, params) {
        const [moduleName, ...matchedMethod] = method.match(/([a-z]+|[A-Z][a-z]+|[A-Z]+)/g);

        let apiUrl = this.composeApiUrl({ version: 'v1' });
        
        const apiClient = restApi(moduleName, apiUrl);
        const apiMethod = toLowerCase(matchedMethod.join(''));

        if (typeof apiClient[apiMethod] !== 'function') {
            return Promise.reject(new Error({ message: 'Wrong function! Please specify correct method name' }));
        }

        try {
            return apiClient[apiMethod](params);
        } catch (e) {
            console.error(`Error making api request by method: ${method} params: ${JSON.stringify(params)}, error: ${JSON.stringify(e)}`);
            //gyLog.error(`Error making api request by method: ${method} params: ${JSON.stringify(params)}, error: ${e}`);

            return e;
        }
    }
}
