<template>
    <div id="app">
        <component :is="layout"></component>
        <router-view :key="$route.fullPath" />
        <Footer/>
    </div>
</template>

<script>
    const default_layout = 'default-navbar';
    import TopNav from '@/components/TopNav';
    import Footer from '@/components/Footer';
    export default {
        name: 'App',
        components: { TopNav, Footer },
        computed: {
            isLoading() {
                return this.$store.state.isLoading;
            },
            layout() {
                return (this.$route.meta.layout || default_layout) + '-layout';
            }
        },
    }
</script>

<style>
  
</style>
