//import '@babel/polyfill'
import Vue from 'vue'
import { I18n, Logger, Hub, Auth, Storage } from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'


import App from './App.vue'
import router from './router'
import store from './store'
import BootstrapVue from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserEdit, faDollarSign, faFileInvoiceDollar, faCogs, faCertificate, faArrowCircleRight, faMinusCircle, faCheck, faCheckCircle, faTimes, faTimesCircle, faExclamationCircle, faChevronCircleRight} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Vuelidate from 'vuelidate'

import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../node_modules/bootstrap-vue/dist/bootstrap-vue.css';
import '@/assets/styles/style.css'

import DefaultNavbar from './layouts/DefaultNavbar.vue';
import RebateNavbar from './layouts/RebateNavbar.vue';
import JournalTicketsNavbar from './layouts/JournalTicketsNavbar.vue';

Vue.component('default-navbar-layout', DefaultNavbar);
Vue.component('rebate-navbar-layout', RebateNavbar);
Vue.component('journal-tickets-navbar-layout', JournalTicketsNavbar);

library.add(faUserEdit, faDollarSign, faFileInvoiceDollar, faCogs, faCertificate, faArrowCircleRight, faMinusCircle, faCheck, faCheckCircle, faTimes, faTimesCircle, faExclamationCircle, faChevronCircleRight)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false;

//window.LOG_LEVEL = 'VERBOSE';

// Event bus
Vue.prototype.$eventBus = new Vue();

Vue.use(BootstrapVue)
Vue.use(AmplifyPlugin, {
    Auth,
    I18n,
    Logger,
    Hub,
    Storage,
})
Vue.use(Vuelidate)

global._ = require('lodash');

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');