export default {
    Cognito: {
        Auth: {
            identityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID,
            region: process.env.VUE_APP_COGNITO_AWS_REGION,
            userPoolId: process.env.VUE_APP_COGNITO_EXTERNAL_USER_POOL_ID,
            userPoolWebClientId: process.env.VUE_APP_COGNITO_EXTERNAL_USER_POOL_CLIENT_ID,
            mandatorySignIn: true,
        },
    },
    GOpass: {
        Auth: {
            identityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID,
            region: process.env.VUE_APP_COGNITO_AWS_REGION,
            userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
            userPoolWebClientId: process.env.VUE_APP_COGNITO_USER_POOL_CLIENT_ID,
            identityProvider: 'gy-azure-oid',
            mandatorySignIn: true,
            oauth: {
                // Domain name
                domain: process.env.VUE_APP_COGNITO_OAUTH_DOMAIN,
                // Authorized scopes
                scope: ['email', 'profile', 'openid'],
                // Callback URL
                redirectSignIn: process.env.VUE_APP_BASE_URL + '/callback',
                // Sign out URL
                redirectSignOut: process.env.VUE_APP_BASE_URL + '/signout',
                // 'code' for Authorization code grant, 'token' for Implicit grant
                responseType: 'code',
                // optional, for Cognito hosted ui specified options
                options: {
                    // Indicates if the data collection is enabled to support Cognito advanced security features.
                    // By default, this flag is set to true.
                    AdvancedSecurityDataCollectionFlag: true,
                },
            },
        },
    },
    Storage: {
        AWSS3: {
            bucket: process.env.VUE_APP_S3_BUCKET_FOR_UPLOADS, // REQUIRED -  Amazon S3 bucket
            region: process.env.VUE_APP_COGNITO_AWS_REGION, // OPTIONAL -  Amazon service region
            identityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID,
        },
    },
    StorageTemplates: {
        AWSS3: {
            bucket: process.env.VUE_APP_S3_BUCKET_FOR_TEMPLATE_UPLOADS,
        }
    }
};
