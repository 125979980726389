export const REDIRECT_HOME_TIMEOUT = 5000;

export const RESPONSE_STATUS_CODES = {
    STATUS_OK: 200,
    STATUS_BAD_REQUEST: 400,
    STATUS_UNAUTHORIZED: 401,
    STATUS_FORBIDDEN: 403,
    STATUS_NOTFOUND: 404,
    STATUS_ERROR: 500,
    STATUS_TIMEOUT: 504,
};

export const VERIFICATION_GENERATION_RANDOM_NUMBER = 32;

export const HIGHEST_ROLE_PRIORITY = 0;

export const LOWEST_ROLE_PRIORITY = 3;

export const ALL_LIST_LIMIT = -1;

export const VALID_REBATE_PBU_LIST = ['OTR', 'CONSUMER', 'COMMERCIAL', 'CANADA'];
