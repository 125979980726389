import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

import auth from './modules/auth';
import storage from './modules/storage';
import requests from './modules/requests';
import reports from './modules/reports';
import sap from './modules/sap';
import tickets from './modules/tickets';

import ApiClient from '../api/apiClient';


import { RESPONSE_STATUS_CODES } from '../config/constants';

const { STATUS_FORBIDDEN, STATUS_NOTFOUND } = RESPONSE_STATUS_CODES;

Vue.use(Vuex);

const api = new ApiClient();

const store = new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    state: {
        isReady: false,
        isLoading: false,
    },
    mutations: {
        setIsReady(state, isReady) {
            state.isReady = isReady;
        },
        toggleLoading(state, loading) {
            state.isLoading = loading;
        },
    },
    actions: {
        resetToDefaults({ state }, ...modules) {
            Object.keys(state).forEach(module => {
                if (modules.includes(module)) {
                    module.commit('resetToDefaults');
                }
            });
        },
    },
    modules: {
        auth,
        storage,
        requests,
        reports,
        sap,
        tickets,
    },
});

store.api = api;

store.commit('setIsReady', false);

// Add JWT token to every API request
axios.interceptors.request.use(
    conf => {
        return store
            .dispatch('auth/getUser')
            .then(({ user = {} }) => {
                const { jwtToken } = user.signInUserSession.idToken;
                const config = conf;

                if (jwtToken) {
                    config.headers.Authorization = `Bearer ${jwtToken}`;
                }

                return config;
            })
            .catch(error => console.error(`Can't get user info, error: ${JSON.stringify(error)}`));
    },
    error => Promise.reject(error),
);

Promise.all([store.dispatch('auth/initAmplify'), store.dispatch('auth/getUser')])
    .then(() => store.commit('setIsReady', true))
    .catch(error => console.error(`Error with init amplify or getting user info, error: ${JSON.stringify(error)}`));

export default store;
