//import globalMixin from '../mixins/global';
//import pagerMixin from '../mixins/pageable';
//import composeModules from '../shared/composeModules';

const defaultState = () => ({
    list: [],
    total: null,
    pager: {},
});

const requests = {
    namespaced: true,
    name: 'requests',
    state: defaultState(),
    actions: {
        async getList({ commit, state }, { ...params }) {
            /*commit('setPagerData', {
                rowsPerPage: params.rowsPerPage,
                page: params.page,
            });*/
            const { data = {}, error = {} } = await this.api.restApiV1('requestsList', { ...state.pager, ...params });
            if (Object.keys(error).length) {
                return Promise.reject(error);
            }

            const { data: list = [], total = null } = data;

            let mappedData = list.map(el => ({
                request_id: el.request_id,
                date_submitted: el.request_date,
                user_id: el.user_id,
                first_name: el.first_name,
                last_name: el.last_name,
                rebate_status: el.current_status,
                rebate_number: el.agreement_no,
                co_nonsig_number: el.common_owner_number,
                common_owner_name: el.common_owner_name,
                subcommon_owner_name: el.subcommon_owner_name,
                pbu: el.pbu_name,
                payment_type: el.payment_type,
                payment_amount: el.payment,
                next_approver: el.next_approver,
                form_state: el.form_state,
                invoice_document: el.invoice_document
                //groups: el.groups.map(({ id, name } = {}) => ({ id, name })),
            }));

            return Promise.resolve({ list: mappedData, total });
        },
        async getInfo({ }, { ...params }) {
            const { data = {}, error = {} } = await this.api.restApiV1('requestsInfo', params);

            if (Object.keys(error).length) {
                return Promise.reject(error);
            }

            const { data: info = {} } = data;
            return Promise.resolve(info);
        },
        async create({ }, { ...params }) {
            const { data = {}, error = {} } = await this.api.restApiV1('requestsCreate', params);

            if (Object.keys(error).length) {
                return Promise.reject(error);
            }

            return Promise.resolve(_.get(data, 'data[0].insertId'));
        },
        async update({ }, { ...params }) {
            const { data = {}, error = {} } = await this.api.restApiV1('requestsUpdate', params);

            if (Object.keys(error).length) {
                return Promise.reject(error);
            }

            return Promise.resolve({ status: data.status });
        },
        async saveChanges({ }, { ...params }) {
            const { data = {}, error = {} } = await this.api.restApiV1('requestsChanges', params);

            if (Object.keys(error).length) {
                return Promise.reject(error);
            }

            return Promise.resolve({ status: data.status });
        },
    },
};

//export default composeModules(composeModules(requests, pagerMixin), globalMixin(defaultState()));
export default requests;
