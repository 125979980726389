import pagerDefault from '../../config/pager';

const defaultState = () => ({
    list: [],
    total: null,
    pager: { ...pagerDefault },
});

const {
    pager: { rowsPerPage: defaultLimit, page: defaultPage },
} = defaultState();

const reports = {
    namespaced: true,
    name: 'reports',
    state: defaultState(),
    mutations: {
        setPagerData(state, { rowsPerPage = defaultLimit, page = defaultPage } = {}) {
            state.pager.rowsPerPage = rowsPerPage;
            state.pager.page = page;
        },
        setList(state, list) {
            state.list = list;
        },
        setTotal(state, total) {
            state.total = total;
        },
        resetPager(state) {
            Object.assign(state, defaultState());
        },
    },
    actions: {
        async getList({ commit, state }, { ...params }) {
            commit('setPagerData', {
                rowsPerPage: params.rowsPerPage,
                page: params.page,
            });
            console.log('state:  ' + state)
            const { data = {}, error = {} } = await this.api.restApiV1('reportsList', { ...state.pager, ...params });
            if (Object.keys(error).length) {
                return Promise.reject(error);
            }

            const { data: list = [], total = null } = data;
            
            return Promise.resolve({ list: list, total });
        },
        async getExportList({}, { ...params }) {

            const { data = {}, error = {} } = await this.api.restApiV1('reportsList', params);
            
            if (Object.keys(error).length) {
                return Promise.reject(error);
            }

            const { data: list = [], total = null } = data;
            let mappedData = [];

            if (params.filters.request_type === 'RBT') {

              mappedData = list.map(el => ({
                "Request ID": el.request_id,
                "Request Date": el.request_creation_date,
                "Requestor ID": el.requestor_user_id,
                "Requestor First Name": el.first_name,
                "Requestor Last Name": el.last_name,
                "Agreement Number": el.agreement_no,
                "Agreement Description": el.agreement_desc,
                "Common Owner Number": el.common_owner_number,
                "Common Owner Name": el.common_owner_name,
                "Customer Group": el.customer_group,
                "Rebate PBU": el.pbu_name,
                "Payer Nonsig": el.payer_nonsig,
                "Valid From Date": el.valid_from_date,
                "Valid To Date": el.valid_to_date,
                "Payment Type": el.payment_type,
                "Payment Amount": el.payment,
                "Rebate Title": el.title,    
                "Credit Memo": el.credit_memo,
                "Funding Amount": el.funding_amount,            
                "Return Amount": el.funding_amount,            
                "Completion Date": el.request_complete_date,
                "Current Status": el.current_status,
                "Next Approver": el.next_approver,
                "Last Submitted Date": el.last_submitted_date,
                "Last Approved Level 1 Date": el.last_approved_level_1_date,
                "Last Approved Level 2 Date": el.last_approved_level_2_date,
                "Payment Period": el.payment_period,
                "Invoice Document": el.invoice_document,
            }));
        } else {
            mappedData = list.map(el => ({
                "Request ID": el.request_id,
                "Request Date": el.request_creation_date,
                "Requestor ID": el.requestor_user_id,
                "Requestor First Name": el.first_name,
                "Requestor Last Name": el.last_name,
                "JT/SDA Number": el.jt_number,
                "SDA Approval Number": el.sda_approval_number,
                "SDA Invoice Ref Number": el.sda_invoice_ref_number,
                "Nonsig Number": el.nonsig_number,
                "Nonsig Name": el.nonsig_name,
                "Street Address": el.nonsig_street_address,
                "City": el.nonsig_city,
                "State": el.nonsig_state,
                "Zip Code": el.nonsig_zip,
                "Common Owner Number": el.common_owner_number,
                "JT/SDA Title": el.title,
                "Payment Type": el.payment_type,
                "Payment Amount": el.payment,
                "Payment Memo": el.payment_memo,
                "Payment Period": el.payment_period,
                "Accouting Info": el.accounting_info,            
                "Material Numbers": el.material_numbers, 
                "SAP Number": el.sap_number,
                "Invoice Number": el.invoice_number,
                "JT/SDA Date": el.jt_date,           
                "Completion Date": el.request_complete_date,
                "Current Status": el.current_status,
                "Next Approver": el.next_approver,
                "Last Submitted Date": el.last_submitted_date,
                "Last Approved Level 1 Date": el.last_approved_level_1_date,
                "Last Approved Level 2 Date": el.last_approved_level_2_date,
                "Invoice Document": el.invoice_document,
            }));
        };
            
            return Promise.resolve({ list: mappedData, total });
        },
        async getInfo({}, { ...params }) {
            const { data = {}, error = {} } = await this.api.restApiV1('reportsInfo', params);

            if (Object.keys(error).length) {
                return Promise.reject(error);
            }

            const { data: info = {} } = data;

            const mappedData = {
                request_id: info.request_id,
                request_date: info.request_date,
                user_id: info.user_id,
                first_name: info.first_name,
                last_name: info.last_name,
                aggreement_no: info.agreement_no,
                aggreement_desc: info.agreement_desc,
                valid_from_date: info.valid_from_date,
                valid_to_date: info.valid_to_date,
                common_owner_number: info.common_owner_number,
                common_owner_name: info.common_owner_name,
                customer_group: info.customer_group,
                pbu_name: info.pbu_name,
                payment_type: info.payment_type,
                payment_amount: info.payment_amount,
                credit_memo: info.credit_memo,
                payment_period: info.payment_period,
            };

            return Promise.resolve(mappedData);
        },
        async getUserList({ commit, state }, { ...params }) {

            const { data = {}, error = {} } = await this.api.restApiV1('reportsUserList', { ...params });
            if (Object.keys(error).length) {
                return Promise.reject(error);
            }

            const { data: list = [], total = null } = data;
            return Promise.resolve({ list: list, total });
        },
        async getStatusList({ commit, state }, { ...params }) {

            const { data = {}, error = {} } = await this.api.restApiV1('reportsStatusList', { ...params });
            if (Object.keys(error).length) {
                return Promise.reject(error);
            }

            const { data: list = [], total = null } = data;
            
            let mappedData = list.map(el => ({
                status_code: el.status_code,
                status: el.status_description,
            }));

            return Promise.resolve({ list: mappedData, total });
        },
        async getHistoryInfo({}, { ...params }) {

            const { data = {}, error = {} } = await this.api.restApiV1('reportsHistoryInfo', params);

            if (Object.keys(error).length) {
                return Promise.reject(error);
            }

            const { data: list = [], total = null } = data;
            return Promise.resolve(list);
        },
    },
};

//export default composeModules(composeModules(requests, pagerMixin), globalMixin(defaultState()));
export default reports;
