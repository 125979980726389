import axios from 'axios';
import { RESPONSE_STATUS_CODES } from '../../config/constants';

const { STATUS_OK } = RESPONSE_STATUS_CODES;

export default apiUrl => {
    const request = async ({ url = '', method = '', data = {} }) => {
        const params = {
            url: url.startsWith('http') ? url : `${apiUrl}${url}`,
            method,
            headers: { 'Content-type': 'application/json' },
        };

        if (Object.keys(data).length) {
            const key = method === 'get' ? 'params' : 'data';

            _.merge(params, { [key]: data });
        }

        return await axios(params)
            .then(response => {
                //console.log('axios response ', JSON.stringify(response))
                if (response && response.status === STATUS_OK) {
                    return Promise.resolve({ data: response.data });
                }

                return Promise.reject(response.response);
            })
            .catch(err => {
                //console.log('axios err ', JSON.stringify(err))
                if (!err.response) {
                    return { error: 'Network error' };
                }

                const { status, data = {} } = err.response;

                /*const error = {
                    head: {
                        label: data.error,
                        status,
                    },
                    payload: data.payload,
                };*/
                const error = {
                    message: data.error,
                    status,
                };

                return { error };
            });
    };

    return {
        get(params = {}) {
            return request({
                method: 'get',
                data: params.data,
                url: params.url,
            });
        },
        post(params = {}) {
            return request({
                method: 'post',
                data: params.data,
                url: params.url,
            });
        },
        put(params = {}) {
            return request({
                method: 'put',
                data: params.data,
                url: params.url,
            });
        },
        patch(params = {}) {
            return request({
                method: 'patch',
                data: params.data,
                url: params.url,
            });
        },
        delete(params = {}) {
            return request({
                method: 'delete',
                url: params.url,
            });
        },
    };
};
