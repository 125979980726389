import Vue from 'vue'
import { Hub } from '@aws-amplify/core';
import Router from 'vue-router'
import Home from '@/components/Home'
import MyRebates from '@/components/MyRebates'
import Search from '@/components/Search'
import NewRebatePayment from '@/components/NewRebatePayment'
import RebateTemplateUpload from '@/components/RebateTemplateUpload'
import Tableau from '@/components/Tableau'
import Page404 from '@/components/Page404';
import SignedOut from '@/components/SignedOut';
import AuthPage from '@/components/AuthPage';
import MainLoader from '@/components/MainLoader';
import MyJournalTickets from '@/components/MyJournalTickets';
import NewJournalTicket from '@/components/NewJournalTicket';
import SearchJournalTickets from '@/components/SearchJournalTickets';
import JournalTicketTemplateUpload from '@/components/JournalTicketTemplateUpload'
import store from '../store';


const REQUIRE_AUTH = !(process.env.VUE_APP_AUTH_MODE && process.env.VUE_APP_AUTH_MODE !== 'true');
const DEFAULT_TITLE = 'Business Operations';

Vue.use(Router)

const router = new Router({
    mode: 'history',
    //base: process.env.VUE_APP_BASE_URL,
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home,
            meta: {
                redirectRules: [{ to: 'Auth', rule: 'nonAuth' }],
                title: 'Business Operations',
                /*metaTags: [
                    {
                        name: 'description',
                        content: 'The home page of our portal.'
                    }
                ]*/
            }
        },
        {
            path: '/my-rebates',
            name: 'My Rebates',
            component: MyRebates,
            meta: {
                layout: "rebate-navbar",
                redirectRules: [{ to: 'Auth', rule: 'nonAuth' }],
                title: 'Rebate Payments',
            }
        },
        {
            path: '/new-rebate',
            name: 'New Rebate Payment',
            component: NewRebatePayment,
            meta: {
                layout: "rebate-navbar",
                redirectRules: [{ to: 'Auth', rule: 'nonAuth' }],
                title: 'Rebate Payments',
            }
        },
        {
            path: '/rebate-template-upload',
            name: 'Upload Rebate Template',
            component: RebateTemplateUpload,
            meta: {
                layout: "rebate-navbar",
                redirectRules: [{ to: 'Auth', rule: 'nonAuth' }],
                title: 'Rebate Payments',
            }
        },
        {
            path: '/search',
            name: 'Search',
            component: Search,
            meta: {
                layout: "rebate-navbar",
                redirectRules: [{ to: 'Auth', rule: 'nonAuth' }],
                title: 'Rebate Payments',
            }
        },
        {
            path: '/my-journal-tickets',
            name: 'My Journal Tickets',
            component: MyJournalTickets,
            meta: {
                layout: "journal-tickets-navbar",
                redirectRules: [{ to: 'Auth', rule: 'nonAuth' }],
                title: 'Journal Tickets',
            }
        },
        {
            path: '/new-journal-ticket',
            name: 'New Journal Ticket',
            component: NewJournalTicket,
            meta: {
                layout: "journal-tickets-navbar",
                redirectRules: [{ to: 'Auth', rule: 'nonAuth' }],
                title: 'Journal Tickets',
            }
        },
        {
            path: '/search-journal-tickets',
            name: 'Search Journal Tickets',
            component: SearchJournalTickets,
            meta: {
                layout: "journal-tickets-navbar",
                redirectRules: [{ to: 'Auth', rule: 'nonAuth' }],
                title: 'Journal Tickets',
            }
        },
        {
            path: '/jt-template-upload',
            name: 'Upload Journal Ticket Template',
            component: JournalTicketTemplateUpload,
            meta: {
                layout: "journal-tickets-navbar",
                redirectRules: [{ to: 'Auth', rule: 'nonAuth' }],
                title: 'Journal Tickets',
            }
        },
        {
            path: '/tableau',
            name: 'Tableau',
            component: Tableau,
            meta: { redirectRules: [{ to: 'Auth', rule: 'nonAuth' }] },
        },
        {
            path: '/auth',
            name: 'AuthPage',
            component: AuthPage,
            meta: {
                redirectRules: [{ to: 'Home', rule: 'auth' }],
            },
        },
        {
            path: '/callback',
            name: 'MainLoader',
            component: MainLoader,
        },
        {
            path: '/signout',
            name: 'SignedOut',
            component: SignedOut,
        },
        {
            path: '/404',
            name: 'Page404',
            component: Page404,
        },
        {
            path: '*',
            redirect: { name: 'Page404' },
        },
    ]
});

router.beforeEach((to, from, next) => {
    store.commit('toggleLoading', true);
    document.title = to.meta.title || DEFAULT_TITLE;
    next();
});

router.beforeResolve((to, from, next) => {
    const [match] = to.matched;

    if (!match) {
        return router.push({ name: 'AuthPage' });
    }

    const { redirectRules = [], access } = match.meta;

    const authNeed = REQUIRE_AUTH && redirectRules.find(({ rule }) => rule === 'nonAuth');
    const accessNeed = Array.isArray(access);  //role required for access
    const authPageRedirect = redirectRules.find(({ rule }) => rule === 'auth');

    if (authNeed || accessNeed || authPageRedirect) {
        store
            .dispatch('auth/getUser')
            .then(() => {
                const isAuthenticated = store.getters['auth/isAuthenticated'];
                //const myuser = store.getters['auth/userSession'];
                //console.log('userSession', myuser);

                if (authNeed) {
                    if (isAuthenticated) {
                        next();
                    } else {
                        return router.push({ name: authNeed.to });
                    }
                }

                if (accessNeed) {
                    const { role } = store.getters['auth/userSession'];

                    if (!role) {
                        console.error(`Expected role to be specified, received: ${role}`);

                        return router.push({ name: 'Page404' });
                    }

                    if (to.matched.some(match => match.meta.access.includes(role))) {
                        next();
                    } else {
                        return router.push({ name: 'Page404' });
                    }
                }

                if (isAuthenticated && authPageRedirect) {
                    return router.push({ name: authPageRedirect.to });
                }

                next();
            })
            .catch(() => { router.push({ name: 'AuthPage' }) });
    } else {
        next();
    }
});

router.afterEach(() => store.commit('toggleLoading', false));

// Listen for Cognito events
const listener = data => {
    if (data.payload.event === 'signIn') {
        router.push({ name: 'Home' });
    }
};

Hub.listen('auth', listener);

export default router;