export default (params, keysMap) => {
    const { filters } = params;

    if (!filters) {
        return {};
    }

    const result = {};

    Object.keys(filters).forEach(key => {
        const value = keysMap[key];

        if (value) {
            result[value] = filters[key];
        }
    });

    return result;
};
