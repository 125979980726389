import restApiRequest from '../apiRequestClients/restApiRequest';
import applyFilterParams from '../utils/filterParser';

const BASE_URL = '/requests';

export default apiUrl => {
    const apiRequest = restApiRequest(apiUrl);

    return {
        list(params = {}) {
            const sendParams = { url: BASE_URL, data: {} };

            Object.assign(sendParams.data, applyFilterParams(params, { role: 'role', request_type: 'request_type'}));

            return apiRequest.get(sendParams);
        },
        info(params = {}) {
            if (!params.request_id || typeof params.request_id !== 'number') {
                throw new Error({ error: `Expected requestId param to be Number, got ${typeof params.request_id}` });
            }

            const sendParams = { url: `${BASE_URL}/${params.request_id}`, data: {} };
            Object.assign(sendParams.data, applyFilterParams(params, { role: 'role', request_type: 'request_type'}));

            return apiRequest.get(sendParams);
        },
        create(params = {}) {
            const sendParams = {
                url: BASE_URL,
                data: {
                    request_type: params.request_type,
                    agreement_no: params.agreement_no,
                    agreement_desc: params.agreement_desc,
                    valid_from_date: params.valid_from_date,
                    valid_to_date: params.valid_to_date,
                    common_owner_number: params.common_owner_number,
                    common_owner_name: params.common_owner_name,
                    customer_group: params.customer_group,
                    pbu_name: params.pbu_name,
                    title: params.title,
                    payment_type: params.payment_type,
                    payer_nonsig: params.payer_nonsig,
                    payment_amount: params.payment_amount,
                    credit_memo: params.credit_memo,
                    additional_notes: params.additional_notes,
                    notify_email_address: params.notify_email_address,
                    subcommon_owner_number: params.subcommon_owner_number,
                    payment_period: params.payment_period
                },
            };

            return apiRequest.post(sendParams);
        },
        update(params = {}) {
            if (!params.request_id || typeof params.request_id !== 'number') {
                throw new Error({ error: `Expected request_id param to be Number, got ${typeof params.request_id}` });
            }
            const sendParams = {
                url: `${BASE_URL}/${params.request_id}`,
                data: {
                    request_type: params.request_type,
                    action: params.action,
                    reason: params.reason,
                    funding_amount: params.funding_amount,
                    return_amount: params.return_amount,
                    additional_notes: params.additional_notes,
                    close_sap_rebate: params.close_sap_rebate,
                    sales_order_number: params.sales_order_number,
                    invoice_number: params.invoice_number,             
                    agreement_no: params.agreement_no,
                    agreement_desc: params.agreement_desc,
                    valid_from_date: params.valid_from_date,
                    valid_to_date: params.valid_to_date,
                    common_owner_number: params.common_owner_number,
                    common_owner_name: params.common_owner_name,
                    customer_group: params.customer_group,
                    pbu_name: params.pbu_name,
                    title: params.title,
                    payment_type: params.payment_type,
                    payer_nonsig: params.payer_nonsig,
                    payment_amount: params.payment_amount,
                    credit_memo: params.credit_memo,
                    notify_email_address: params.notify_email_address,
                    subcommon_owner_number: params.subcommon_owner_number,
                    payment_period: params.payment_period,
                    invoice_document: params.invoice_document
                },
            };
            console.log('sendParams: ', sendParams)

            return apiRequest.post(sendParams);
        },
        changes(params = {}) {
            if (!params.request_id || typeof params.request_id !== 'number') {
                throw new Error({ error: `Expected request_id param to be Number, got ${typeof params.request_id}` });
            }

            const sendParams = {
                url: `${BASE_URL}/${params.request_id}`,
                data: {
                    form_state: params.form_state,
                    additional_notes: params.additional_notes,
                    history_id: params.history_id,
                    additional_notes_id: params.additional_notes_id,
                    invoice_document: params.invoice_document,
                    request_type: params.request_type,
                },
            };

            return apiRequest.patch(sendParams);
        },
    };
};
