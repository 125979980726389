import { ALL_LIST_LIMIT } from '../../config/constants';

export default params => {
    const { rowsPerPage = null, page = null } = params;
    const result = {};

    if (rowsPerPage && rowsPerPage !== ALL_LIST_LIMIT) {
        result.limit = rowsPerPage;
    }

    if (page && rowsPerPage !== ALL_LIST_LIMIT) {
        result.offset = (page - 1) * rowsPerPage;
    }

    return result;
};
